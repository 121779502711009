import { ILocation } from "./location.model";
import { User } from "./user.model";

export enum OVERTIMEOPTIONS {
  WEEKLY = 1,
  DAILY = 2,
};

export class LiaisonAccount {
  id: number;
  name: string;
  base_hours?: number;
  pay_rate?: number;
  active?: boolean;
  customer_id?: number;
  location_id?: number;
  locations?: {name: string} | ILocation;
  liaison_accounts?: ILiaisonAccount[];
  overtime_hours?: number;
  no_overtime?: boolean;
  overtime_holidays?: boolean;
  overtime_weekends?: boolean;
  doubletime_holidays?: boolean;
  doubletime_sundays?: boolean;
  overtime_option?: OVERTIMEOPTIONS;
  invoice_rate?: number;
  isCrossCoverage?: boolean;
  qbo_name?: string;
}

export interface ILiaisonAccountHistory {
  id?: number;
  hours: number;
  end_date: string | null;
  start_date: string | null;
  rate: number;
  liaison_account: number;
  liaison: number;
}

export interface ILiaisonAccount {
  id?: number;
  account_id: number;
  base_hours: number;
  pay_rate: number;
  user_id: number;
  pay_rate_value?: number;
  base_hours_value?: number;
  liaison_account_history?: any[];
  users?: User;
  accounts?: any;
  isUpcoming?: boolean;
}

