<ion-grid>
    <ion-row class="header-row">
        <ion-col class="back-button" size="auto">
            <div (click)="back()" [ngStyle]="{'opacity': (this.activeTab === Tabs.CUSTOMER) ? '0' : '1'}">
                <ion-icon name="chevron-back-outline"></ion-icon>
                <span>back</span>
            </div>
        </ion-col>
        <ion-col>
            <div class="stepper-wrapper">
                <div class="stepper-item" (click)="updateActiveTab(Tabs.CUSTOMER)"
                 [ngClass]="{'active': (this.activeTab === Tabs.CUSTOMER), 'completed': (this.activeTab > Tabs.CUSTOMER)}">
                  <div class="step-counter"><span>1</span></div>
                  <div class="step-name"><span>Account</span></div>
                </div>
                <div *ngIf="sharedService.getAuthorizationStatus()" class="stepper-item" (click)="updateActiveTab(Tabs.ACCOUNT)"
                [ngClass]="{'active': (this.activeTab === Tabs.ACCOUNT), 'completed': (this.activeTab > Tabs.ACCOUNT)}">
                  <div class="step-counter"><span>2</span></div>
                  <div class="step-name"><span>Rate/Hours</span></div>
                </div>
                <div class="stepper-item" (click)="updateActiveTab(Tabs.LIAISON)"
                [ngClass]="{'active': (this.activeTab === Tabs.LIAISON), 'completed': (this.activeTab > Tabs.LIAISON)}">
                  <div class="step-counter"><span>{{sharedService.getAuthorizationStatus() ? '3' : '2'}}</span></div>
                  <div class="step-name"><span>Liaisons</span></div>
                </div>
                <div class="stepper-item" (click)="updateActiveTab(Tabs.CONTACTS)"
                [ngClass]="{'active': (this.activeTab === Tabs.CONTACTS), 'completed': (this.activeTab > Tabs.CONTACTS)}">
                  <div class="step-counter"><span>{{sharedService.getAuthorizationStatus() ? '4' : '3'}}</span></div>
                  <div class="step-name"><span>Contacts</span></div>
                </div>
              </div>
        </ion-col>
        <ion-col class="close-button" size="auto">
            <ion-icon class="cross" (click)="closeModal()" name="close-outline"></ion-icon>
        </ion-col>
    </ion-row>
    <div class="form-container" *ngIf="loaded">

        <!-- CUSTOMER SECTION -->
        <div *ngIf="this.activeTab === Tabs.CUSTOMER">
    
            <div class="double-row">
                <div>
                    <ion-row class="label-row margin-t-10">
                        <ion-col class="ion-no-padding label-container">
                            <ion-label>
                                Account Name
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <!-- <input [(ngModel)]="invoiceRate" prefix="$" type="text" placeholder="Enter Invoice Rate" mask="0*.00" /> -->
                            <ion-input [disabled]="!sharedService.getAuthorizationStatus()" class="full-width" [(ngModel)]="account.name" [placeholder]="'Enter Account Name'"></ion-input>
                        </ion-col>
                    </ion-row>
                </div>
    
                <div>
                    <ion-row class="label-row margin-t-10">
                        <ion-col class="ion-no-padding label-container">
                            <ion-label>
                                Quickbooks Name
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <!-- <input [(ngModel)]="invoiceRate" prefix="$" type="text" placeholder="Enter Invoice Rate" mask="0*.00" /> -->
                            <ion-input [disabled]="!sharedService.getAuthorizationStatus()" class="full-width quickbooks-input" [(ngModel)]="account.qbo_name" [placeholder]="'Enter Quickbooks Name'"></ion-input>
                        </ion-col>
                    </ion-row>
                </div>
            </div>

            <div class="double-row">
                <div>
                    <ion-row class="label-row margin-t-10">
                        <ion-col class="ion-no-padding label-container">
                            <ion-label>
                                Program/Vehicle Lines
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input [disabled]="!sharedService.getAuthorizationStatus()" [(ngModel)]="account.program" [placeholder]="'Enter Program/Vehicle Lines'"></ion-input>
                        </ion-col>
                    </ion-row>
                </div>
    
                <div>
                    <ion-row class="label-row margin-t-10">
                        <ion-col class="ion-no-padding label-container">
                            <ion-label>
                                Product Description
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input [disabled]="!sharedService.getAuthorizationStatus()" [(ngModel)]="account.product_description" [placeholder]="'Enter Product Description'"></ion-input>
                        </ion-col>
                    </ion-row>
                </div>
            </div>

            <div class="double-row">
                <div>
                    <ion-row class="label-row margin-t-10">
                        <ion-col class="ion-no-padding label-container">
                            <ion-label>
                                Location
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <select [disabled]="!sharedService.getAuthorizationStatus()" class="full-width" (change)="locationUpdated()" [(ngModel)]="selectedLocation" [ngClass]="{'text-color-grey': !selectedLocation}">
                                <option [value]="null" disabled selected>Select Location</option>
                                <option *ngFor="let location of locations | activeFilter" [ngValue]="location.id">
                                    {{location.name}}
                                </option>
                            </select>
                        </ion-col>
                    </ion-row>
                </div>

                <div>
                    <ion-row class="label-row margin-t-10">
                        <ion-col class="ion-no-padding label-container">
                            <ion-label>
                                PO Number
                            </ion-label>
                        </ion-col>
                    </ion-row>
                    <ion-row class="center-item">
                        <ion-col>
                            <ion-input (keypress)="omitSemiColon($event)" [disabled]="!sharedService.getAuthorizationStatus()" [(ngModel)]="account.po_number" [placeholder]="'Enter PO Number'"></ion-input>
                        </ion-col>
                    </ion-row>
                </div>
            </div>
        </div>

        <!-- ACCOUNT SECTION -->
        <div *ngIf="(this.activeTab === Tabs.ACCOUNT) && sharedService.getAuthorizationStatus()" class="account-area">
    
            <div class="scroll-area">
                <ng-container>
                    <div class="left-column">
                        <!-- <ion-row class="new-row selectable" (click)="addNew()">
                            <div>
                                <span>+ New</span>
                            </div>
                        </ion-row> -->
                        <ion-row class="new-row">
                            <div>
                                <span>History</span>
                            </div>
                        </ion-row>
                        <ion-row class="table-row" *ngFor="let data of history | orderByStartDate: trigger" (click)="setData(data)">
                            
                            <div [ngClass]="{'active-date': isSelectedData(data)}" class="selectable date">
                                <span class="date-span">{{data.start_date | date:'shortDate'}}</span>
                                <span class="center-dash"> - </span>
                                <span class="date-span">
                                    {{data.end_date ? (getDisplayEndDate(data.end_date) | date:'shortDate') : 'Future'}}</span>     
                            </div>
        
                        </ion-row>
                        <!-- *ngIf="!isLastEntry(selectedData)" -->
                        <ion-row class="remove-row selectable" (click)="remove(selectedData)">
                            <div>
                                <span>- Remove</span>
                            </div>
                        </ion-row>
                    </div>
                    <div class="right-column">
  
                        <ion-row class="label-row full-width margin-t-10">
                            <ion-col class="ion-no-padding label-container">
                                <ion-label>
                                    Invoice Rate *
                                </ion-label>
                            </ion-col>
                            <ion-col class="ion-no-padding label-container">
                                <ion-label>
                                    Contracted Hours *
                                </ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row class="double-row full-width">
                            <ion-col class="ion-no-padding currency-col">
                                <ng-container *ngIf="!refreshCurrencyInput">
                                    <currency-input [fieldChanged]="isFieldChanged('invoice_rate')" [width]="100" [(value)]="selectedData.invoice_rate"
                                     [placeholder]="'Enter Invoice Rate'"></currency-input>
                                </ng-container>
                                <ng-container *ngIf="refreshCurrencyInput">
                                    <currency-input [width]="100" [placeholder]="'Enter Invoice Rate'"></currency-input>
                                </ng-container>
                            </ion-col>
                            <ion-col class="ion-no-padding date-col">
                                <ion-input [ngClass]="{'field-changed': isFieldChanged('contracted_hours')}" class="full-width" type="number" 
                                [(ngModel)]="selectedData.contracted_hours" [placeholder]="'Enter Contracted Hours'"></ion-input>
                            </ion-col>
                        </ion-row>

                        <ion-row class="label-row full-width margin-t-10">
                            <ion-col class="ion-no-padding label-container">
                                <ion-label>
                                    Start Date
                                </ion-label>
                            </ion-col>
                            <ion-col class="ion-no-padding label-container">
                                <ion-label>
                                    End Date
                                </ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row class="double-row full-width">
                            <ion-col class="ion-no-padding date-col">
                                <ion-input (ionChange)="dateChange()" [ngClass]="{'field-changed': isFieldChanged('start_date')}" class="full-width"
                                 type="date" [(ngModel)]="selectedData.start_date"></ion-input>
                            </ion-col>
                            <ion-col class="ion-no-padding date-col">
                                <ion-input (ionChange)="dateChange()" [ngClass]="{'field-changed': isFieldChanged('end_date')}" class="full-width"
                                 type="date" [(ngModel)]="selectedData.end_date"></ion-input>
                            </ion-col>
                        </ion-row>

                        <ion-row class="full-width no-overtime-row sign-contract">
                            <ion-col>
                                <ion-checkbox [(ngModel)]="selectedData.signed_contract"></ion-checkbox>
                                <span class="small-text">Signed Contract</span>
                            </ion-col>
                        </ion-row>

                        <ion-row class="full-width label-row margin-t-10">
                            <ion-col class="ion-no-padding label-container">
                                <ion-label>
                                    Overtime Rules
                                </ion-label>
                            </ion-col>
                        </ion-row>
                        <ion-row class="full-width no-overtime-row">
                            <ion-col>
                                <ion-checkbox [(ngModel)]="selectedData.has_overtime"></ion-checkbox>
                                <span class="small-text">Overtime</span>
                            </ion-col>
                        </ion-row>
                        <ng-container *ngIf="selectedData.has_overtime">
                            <ion-row class="full-width hours-row">
                                <ion-col size="auto">
                                    <span class="small-text">Overtime if hours exceed</span>
                                </ion-col>
                                <ion-col class="overtime-hours" size="auto">
                                    <ion-input [(ngModel)]="selectedData.overtime_hours" placeholder=""></ion-input>
                                </ion-col>
                                <ion-col size="auto">
                                    <select [(ngModel)]="selectedData.overtime_option">
                                        <option [value]="OVERTIMEOPTIONS.WEEKLY">
                                            Weekly
                                        </option>
                                        <option [value]="OVERTIMEOPTIONS.DAILY">
                                            Daily
                                        </option>
                                    </select>
                                </ion-col>
                            </ion-row>
                            <ion-row class="full-width checkbox-row">
                                <ion-col size="auto" class="checkbox-col weekend-overtime-col">
                                    <ion-checkbox labelPlacement="end" [(ngModel)]="selectedData.overtime_weekends"></ion-checkbox>
                                    <span class="small-text">Overtime on Weekends</span>
                                </ion-col>
                                <ion-col size="auto"  class="checkbox-col">
                                    <ion-checkbox labelPlacement="end" [(ngModel)]="selectedData.overtime_holidays"></ion-checkbox>
                                    <span class="small-text">Overtime on Holidays</span>
                                </ion-col>
                            </ion-row>
                            <ion-row class="full-width checkbox-row">
                                <ion-col size="auto"  class="checkbox-col">
                                    <ion-checkbox labelPlacement="end" [(ngModel)]="selectedData.doubletime_sundays"></ion-checkbox>
                                    <span class="small-text">Double Time on Sundays</span>
                                </ion-col>
                                <ion-col size="auto"  class="checkbox-col">
                                    <ion-checkbox labelPlacement="end" [(ngModel)]="selectedData.doubletime_holidays"></ion-checkbox>
                                    <span class="small-text">Double Time on Holidays</span>
                                </ion-col>
                            </ion-row>
                        </ng-container>

                    </div>
                </ng-container>
        
            </div>

        </div>

        <!-- LIAISON SECTION -->
        <div *ngIf="this.activeTab === Tabs.LIAISON">

            <div class="liaison-scroll">
                <ion-row class="liaison-header-row liaison-row">
                    <ion-col class="name-col">
                        Name
                    </ion-col>
                    <ion-col class="data-col">
                        Pay Rate
                    </ion-col>
                    <ion-col class="data-col">
                        Base Hours
                    </ion-col>
                    <ion-col class="data-col">
                        <div class="show-inactive">
                            <ion-toggle [(ngModel)]="showInactiveLiaisonAccount"></ion-toggle>
                            <span>Show Inactive</span>
                        </div>
                    </ion-col>
                </ion-row>
     
                <ng-container *ngFor="let liaison of getLiaisonAccounts()">
                    <ion-row class="liaison-row" [ngClass]="{'inactive-liaison-row': !sharedService.isActiveLiaisonAccount(liaison), 'upcoming-liaison-row': liaison.isUpcoming}">
                        <ion-col class="name-col">
                            {{liaison.users.first_name}} {{liaison.users.last_name}}
                        </ion-col>
                        <ion-col class="data-col">
                            {{(liaison.pay_rate_value ?? 0) | currency:'USD'}}
                        </ion-col>
                        <ion-col class="data-col">
                            {{liaison.base_hours_value ?? 0}}
                        </ion-col>
                        <ion-col class="data-col">
                            <div (click)="editLiaisonList(liaison)" class="edit-button">Edit</div>
                        </ion-col>
                    </ion-row>
                    <ng-container *ngIf="liaison.liaison_account_cover?.length > 0">
                        <ion-row class="cross-cover-row cross-cover-row-border">
                            <ion-col>
                                Active Cross Coverage
                            </ion-col>
                            <ion-col>
                                Rate
                            </ion-col>
                            <ion-col>
                                Date Range
                            </ion-col>
                        </ion-row>

                        <ion-row class="cross-cover-row" *ngFor="let cc of liaison.liaison_account_cover">
                            <ion-col>
                                {{cc.users.first_name}} {{cc.users.last_name}}
                            </ion-col>
                            <ion-col>
                                {{cc.pay_rate ?? (liaison.pay_rate_value ?? 0) | currency:'USD'}}
                            </ion-col>
                            <ion-col>
                                <!-- convert date so it doesnt roll back a day -->
                                {{convertDate(cc.start_date) | date:'shortDate'}} - {{cc.end_date ? (cc.end_date | date:'shortDate') : 'Future'}}
                            </ion-col>
                        </ion-row>
                    </ng-container>
                </ng-container>

                <ion-row class="add-liaison-row">
                    <ion-col>
                        <div (click)="addNewLiaison()">+ Liaison</div>
                    </ion-col>
                    <ion-col class="total-hours-text">
                        Total hours: {{totalBaseHours}}
                    </ion-col>
                </ion-row>

            </div>

            <ion-row class="top-liaison-data">
                <ion-col class="ion-no-padding">
                    <div>
                        <span><ng-container *ngIf="getCurrentInvoiceRateAndHours().isUpcoming">Upcoming</ng-container
                            ><ng-container *ngIf="!getCurrentInvoiceRateAndHours().isUpcoming">Current</ng-container> 
                            Contracted Hours: {{getCurrentInvoiceRateAndHours()?.hours}}</span>
                    </div>
                    <div *ngIf="sharedService.getAuthorizationStatus()" class="invoice-rate">
                        <span><ng-container *ngIf="getCurrentInvoiceRateAndHours().isUpcoming">Upcoming</ng-container>
                            <ng-container *ngIf="!getCurrentInvoiceRateAndHours().isUpcoming">Current</ng-container> 
                            Invoice Rate: {{(getCurrentInvoiceRateAndHours()?.invoiceRate ?? 0) | currency:'USD'}}</span>
                    </div>
                </ion-col>
            </ion-row>

            <div class="hours-changing-section">
                <div class="discrepancies-text" *ngIf="discrepancyList?.length > 0">
                    <span>Contracted hours discrepancies found</span>
                </div>
                <div class="discrepancies-timeline" *ngFor="let history of discrepancyList">
                    <div class="date">
                        <span>{{history.start}} - {{history.end ?? 'Future'}}</span>
                    </div>
                    <div class="value">
                        <span>Contracted hours is {{history.accountTotal}} and assigned hours is {{history.liaisonTotal}}</span>
                    </div>
                </div>
            </div>
        </div>

        <!-- CONTACT SECTION -->
        <div *ngIf="this.activeTab === Tabs.CONTACTS">
            <div class="table">
                <div class="container">
                    <ion-row class="contact-table-label">
                        <ion-col>
                            <span>Quality Contact *</span>
                        </ion-col>
                        <ion-col size="auto">
                            <ion-button (click)="createNewContact()" class="new-button">
                                + New
                            </ion-button>
                        </ion-col>
                    </ion-row>
                    <ion-row class="assign-row" *ngFor="let contact of account.account_contacts">
                        <ion-col>
                            <select *ngIf="sharedService.isNative()" [(ngModel)]="contact.contact_id" [ngClass]="{'text-color-grey': !contact.contact_id}">
                                <option [value]="null" disabled selected>Select Contact</option>
                                <option *ngFor="let allContact of contacts | filterAssignedContacts: assignedContacts : contact.contact_id : manualTriggerContact" [ngValue]="allContact.id">
                                    {{allContact.first_name}} {{allContact.last_name}}
                                </option>
                            </select>
                           <ng-select placeholder="Select Contact" *ngIf="!sharedService.isNative()" appendTo="body" class="ng-select-no-border"
                            [(ngModel)]="contact.contact_id" [ngClass]="{'text-color-grey': !contact.contact_id}">
                               <ng-option *ngFor="let allContact of contacts | filterAssignedContacts: assignedContacts : contact.contact_id : manualTriggerContact" [value]="allContact.id">
                                   {{allContact.first_name}} {{allContact.last_name}}
                               </ng-option>
                            </ng-select>
                        </ion-col>
                    </ion-row>
                </div>
            </div>
        </div>

    </div>
    <div *ngIf="!loaded" class="loading">
        <ion-row>
            <ion-col>
                <ion-spinner></ion-spinner>
            </ion-col>
        </ion-row>
    </div>
    <ion-row class="button-row">
        <ion-col>
            <ion-button *ngIf="!(this.activeTab === Tabs.CONTACTS) && !(this.activeTab === Tabs.ACCOUNT)" (click)="next()">
                <span>Next</span>
                <ion-icon class="next-icon" name="chevron-forward-outline"></ion-icon>
            </ion-button>
            <ion-button *ngIf="(this.activeTab === Tabs.ACCOUNT)" 
            [disabled]="!this.selectedData.invoice_rate || (this.selectedData.contracted_hours === null) || !this.selectedData.start_date || this.accountHistorySaveInProgress" 
            (click)="currentEntryMatches() ? next() : saveAndNext()">
                <span *ngIf="currentEntryMatches()">Next</span>
                <span *ngIf="!currentEntryMatches()">Save</span>
                <ion-icon *ngIf="currentEntryMatches()" class="next-icon" name="chevron-forward-outline"></ion-icon>
            </ion-button>
            <ion-button (click)="finish()" *ngIf="(this.activeTab === Tabs.CONTACTS)">
                <span>Finish</span>
            </ion-button>
        </ion-col>
    </ion-row>

</ion-grid>