<div class="top-card card">

    <ion-row class="title-row">
        <ion-col class="text-col">
            <span>Accounts</span>
        </ion-col>

        <ion-col class="button-col">
            <div class="toggle-container">
                <span>Show Inactive</span>
                <ion-toggle [(ngModel)]="showInactiveAccounts"></ion-toggle>    
            </div>
            <ion-button *ngIf="sharedService.getAuthorizationStatus() || liaisonId" (click)="liaisonId ? addNewLiaisonAccount() : addNewAccount()">{{liaisonId ? '+ Assign' : '+ New' }}</ion-button>
        </ion-col>
    </ion-row>

    <ng-container *ngIf="loaded && ((showInactiveAccounts && (accounts.length > 0)) || (!showInactiveAccounts && (getActiveAccounts().length > 0)))">
        <sort-container [data]="accounts" (updatedData)="updatedData($event)">  
            <ion-row class="header">
    
                <ng-container *ngIf="!liaisonId">
                    <ion-col class="name-header">
                        <sort-header text="Name" identifier="name"></sort-header>
                    </ion-col>
                    <ion-col class="address-header">
                        <sort-header text="Location" identifier="locations.name"></sort-header>
                    </ion-col>
                    <ion-col class="max-width-col-sm" >
                        <sort-header text="Liaison" identifier="liaison_accounts.0.users.first_name"></sort-header>
                    </ion-col>
                    <ion-col class="max-100">
                        <sort-header text="Total Hours" identifier="totalHours"></sort-header>
                    </ion-col>
                </ng-container>
    
                <ng-container *ngIf="liaisonId">
                    <ion-col class="name-header">
                        <sort-header text="Name" identifier="account.name"></sort-header>
                    </ion-col>
                    <ion-col class="address-header">
                        <sort-header text="Customer" identifier="account.customers.company_name"></sort-header>
                    </ion-col>
                    <ion-col>
                        <sort-header text="Location" identifier="account.locations.name"></sort-header>
                    </ion-col>
                    <ion-col class="small-col">
                        <sort-header text="Pay Rate" identifier="basePay"></sort-header>
                    </ion-col>
                    <ion-col class="small-col">
                        <sort-header text="Base Hours" identifier="baseHours"></sort-header>
                    </ion-col>
                    <!-- <ion-col class="swap-icon-col" [ngStyle]="{'opacity': 0}">
                    </ion-col> -->
                </ng-container>
            </ion-row>
        </sort-container>
    </ng-container>

    <div *ngIf="loaded && ((showInactiveAccounts && (accounts.length > 0)) || (!showInactiveAccounts && (getActiveAccounts().length > 0)))" class="list-container">
        <ng-container *ngFor="let account of accounts | refresh: trigger">

            <ion-row class="row" *ngIf="(liaisonId ? isActive(account.account, account.isCrossCoverage) : isActive(account)) || showInactiveAccounts"
             [ngClass]="{'inactive-row': (liaisonId ? !isActive(account.account, account.isCrossCoverage) : !isActive(account)), 'upcoming-row': account.isUpcoming}" (click)="liaisonId ? editLiaisonAccount(account) : editAccount(account)">

                <ion-col>
                    {{liaisonId ? account.account.name : account.name}}
                </ion-col>

                <ion-col>
                    {{!customerId ? (liaisonId ? account.account.customers.company_name : account.customers.company_name) : (account?.qbo_name ?? account?.locations?.name)}}
                </ion-col>

                <ng-container *ngIf="!liaisonId">
                    <ion-col class="max-width-col-sm" >
                        <ion-row class="scroll-x">
                            <ion-col size="auto" *ngFor="let liaison of getLiaisonAccounts(account); let i = index" class="ion-no-padding liaison-col">
                                {{getUserById(liaison.user_id)?.first_name}} {{getUserById(liaison.user_id)?.last_name}}<span *ngIf="i !== (getLiaisonAccounts(account).length - 1)">,&nbsp;</span> 
                            </ion-col>
                            <ion-col class="ion-no-padding" *ngIf="getLiaisonAccounts(account).length <= 0">
                                No Liaisons
                            </ion-col>
                        </ion-row>
                    </ion-col>
    
                    <ion-col class="max-100">
                        {{account.totalHours}}
                    </ion-col>
                </ng-container>
        
                <ng-container *ngIf="liaisonId">
                    <ion-col>
                        {{account?.account?.qbo_name ?? account?.account?.locations?.name}}
                    </ion-col>
                    <ion-col class="small-col">
                        {{account.basePay | currency:'USD'}}
                    </ion-col>
                    <ion-col class="small-col">
                        <ng-container *ngIf="!account.isCrossCoverage || !account.crossCover">
                            {{account.baseHours}} 
                        </ng-container>
                        <span *ngIf="account.isCrossCoverage && account.crossCover">CC</span>
                        <ion-icon *ngIf="account.isCrossCoverage && !account.crossCover" class="swap-icon" name="swap-horizontal-outline"></ion-icon>
                    </ion-col>
                    <!-- <ion-col class="swap-icon-col" [ngStyle]="{'opacity': account.isCrossCoverage ? 1 : 0}">
                        <ion-icon name="swap-horizontal-outline"></ion-icon>
                    </ion-col> -->
                </ng-container>

            </ion-row>

        </ng-container>
    </div>

    <div class="no-content" *ngIf="loaded && ((showInactiveAccounts && (accounts.length <= 0)) || (!showInactiveAccounts && (getActiveAccounts().length <= 0)))">
        <ion-row>
            <ion-col>
                No Accounts
            </ion-col>
        </ion-row>
    </div>

    <div class="loading" *ngIf="!loaded">
        <ion-spinner name="dots"></ion-spinner>
    </div>

</div>